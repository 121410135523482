var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"categories"}},[_c('v-container',{staticClass:"my-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Ara","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return (function () {
                  _vm.dialogContent = 'Add';
                  _vm.dialog = true;
                })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("person_add")]),_c('span',[_vm._v("Kategori Ekle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getItems}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("refresh")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Yükleniyor..."},scopedSlots:_vm._u([{key:"item.dateCreated",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCreated.toLocaleString("tr-TR"))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("create")])],1),_c('v-list-item-title',[_vm._v("Düzenle")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Sil")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(_vm.dialogContent,{tag:"component",attrs:{"category":_vm.selectedItem},on:{"close":function($event){_vm.dialog = false},"refresh":_vm.getItems}})],1),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }